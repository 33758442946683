import i18n from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import Loading from '../../components/Loading/Loading';

const PopUp = ({ show, onHide, popUpData, isLoading }) => {
    const [banglatranslate, setBanglaTraslate] = useState();

    const playerRef = useRef(null);
    const { t } = useTranslation();
    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    const handleButtonClick = () => {
        onHide();
    };

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={onHide}
            // backdrop="static"
        >
            <div className="rounded shadow-lg">
                {/* <Modal.Header ></Modal.Header> */}
                <Modal.Body className="p-2">
                    <div className="position-relative">
                        <p
                            onClick={handleButtonClick}
                            className="p-1 rounded-circle d-flex align-items-center justify-content-center m-auto"
                            style={{
                                position: 'absolute',
                                right: '-15px',
                                backgroundColor: '#fff',
                                top: '-20px',
                                height: '30px',
                                width: '30px',
                                cursor: 'pointer',
                            }}
                        >
                            X
                        </p>
                        <div className="">
                            {/* {popUpData?.type === 'Video' ? (
                                <div className="ratio ratio-4x3">
                                    <ReactPlayer
                                        className="w-100 h-100"
                                        ref={playerRef}
                                        url={popUpData?.videoLink}
                                        controls={true}
                                    />
                                </div>
                            ) : ( */}
                            <img
                                className="img-fluid"
                                style={{width: '100%', height: '100%'}}
                                src={popUpData?.imageURL}
                                alt=""
                            />
                            {/* )} */}
                        </div>
                        {/* <div className="col-md-6 p-3">
                            <h1 className="mb-3 title">
                                {popUpData?.popUpTitle}
                            </h1>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: popUpData?.popUpDec,
                                    }}
                                ></span>
                            </p>
                        </div> */}
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default PopUp;
