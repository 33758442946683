import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
// import { useCookies } from "react-cookie";
import { BsDashLg, BsEmojiSmile, BsFacebook, BsInstagram, BsSendFill, BsYoutube } from "react-icons/bs";
import { FaGift } from "react-icons/fa";
import { ImLinkedin } from "react-icons/im";
import { Link, Outlet } from "react-router-dom";
import lottieDigitalBox from "../assets/icon/lottie-gift-box.json";
import MessangerIcon from "../assets/icon/message.png";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
//import PopUp from "../pages/PopUp/PopUp";
import Footer from "../pages/Shared/Footer/Footer";
import Header from "../pages/Shared/Header/Header";
import "./main.css";

function Main() {
  // const [isOpen, setIsOpen] = useState(false);

  //const [popUpData, setPopUpData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  //const [showPopup, setShowPopup] = useState(false);
  // const [cookies, setCookie] = useCookies(["popupShown"]);

  // useEffect(() => {
  //   if (!cookies.popupShown) {
  //     setShowPopup(true);
  //     setCookie("popupShown", true, { maxAge: 604800 }); // Expires in 1 week (you can change this)
  //   }
  // }, [cookies.popupShown, setCookie]);

  // useEffect(() => {
  //   if (sessionStorage.getItem("popup") > 0) {
  //     return;
  //   }
  //   setTimeout(() => {
  //     setShowPopup(true);
  //     sessionStorage.setItem("popup", 1);
  //   });
  // }, []);

  // const closePopup = () => {
  //   setShowPopup(false);
  // };

  // // const togglePopup = () => {
  // //   setIsOpen(!isOpen);
  // // };

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_host}/api/v1/pop-up`, {
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setPopUpData(data?.data?.popUpList[0]);
  //       setIsLoading(false);
  //     });
  // }, []);

  return (
    <>
      <div>
        <Header />
        <ScrollToTop />
        <div className="position-relative">
          <div className="social-icon-container d-none d-lg-block">
            <div className="social-icon-card">
              <Link to="https://www.facebook.com/GLIL.bd/" target="_blank">
                <BsFacebook className="social-icon" />
              </Link>
              <Link to="https://www.instagram.com/guardian_life_insurance_ltd/" target="_blank">
                <BsInstagram className="social-icon" />
              </Link>
              <Link to="https://www.linkedin.com/company/guardian-life-insurance-limited/" target="_blank">
                <ImLinkedin className="social-icon" />
              </Link>
              <Link to="https://www.youtube.com/channel/UCH0hpdVbitkYbyCUhQYQiKw" target="_blank">
                <BsYoutube className="social-icon" />
              </Link>
            </div>
          </div>

          <div className="messanger-container">
            {/* {isOpen && (
            <div className="message-pupop-containr">
              <div>
                <div className="messanger-header">
                  <div className="header-content">
                    <AiOutlineUser className="user-icon" />
                    <span className="header-text">Welcome to Live Chat!</span>
                  </div>
                  <div>
                    <BsDashLg onClick={togglePopup} className="close-icon" />
                  </div>
                </div>
                <div className="messanger-body">
                  <div className="text-messages">
                    <div>
                      <AiOutlineUser className="user-icon user-send-icon" />
                    </div>
                    <p className="user-send-text">Hello</p>
                  </div>
                </div>
                <div className="messanger-footer">
                  <div className="messanger-footer-content">
                    <div className="write-message">
                      <textarea
                        className="form-control text-message"
                        placeholder="Write a message"
                      ></textarea>
                    </div>
                    <div className="send-text">
                      <BsEmojiSmile className="emoji" />
                      <BsSendFill className="send-btns" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

            {/* <div className="messanger-img">
            <img onClick={togglePopup} src={MessangerIcon} />
          </div> */}
          </div>

          <div className="digital-container">
            <div className="digital-icon">
              {/* <img onClick={togglePopup} src={MessangerIcon} /> */}
              <Link to="/digital-loyalty-offer">
                {/* <Lottie animationData={lottieDigitalBox} loop={true} /> */}
                <FaGift className="digital-icon" />
              </Link>
            </div>
          </div>
        </div>
        <div>
          <Outlet />
        </div>
        <Footer />
      </div>

      {/* {popUpData?.popUpStatus === true && (
        <div>
          <PopUp isLoading={isLoading} popUpData={popUpData} show={showPopup} onHide={closePopup} />
        </div>
      )} */}
    </>
  );
}

export default Main;
