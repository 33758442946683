import React, { useEffect } from "react";
import BgBannerImg from "../../../assets/BG/bgLeft.png";
import BannerImg from "../../../assets/easylife/banner/banner-1.jpg";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};


function AboutEsayLifeBanner() {
  const [isShowMore, setShowMore] = useState(false);
  const handleShow = () => {
    setShowMore(!isShowMore);
  };

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const bannerItems = [
    {
      id: 1,
      title: `${t("easylifeAboutUsBannerTitle")}`,
      desc: `${t("easylifeAboutUsBannerDetails")}`,
      img: BannerImg,
    },
  ];
  return (
    <div style={BgStyle}>
      <div className="body-container secondary-container banner-container">
        {bannerItems.map((bannerItem) => (
          <div key={bannerItem.id} className="banner-content">
            <div className="left-content">
              <>
                <h1 className="title banner-title">{bannerItem?.title}</h1>

                <p className="text banner-text">
                  {!isShowMore
                    ? `${bannerItem?.desc.slice(0, 550)}....`
                    : bannerItem?.desc}
                </p>
                <div className="mt-4 d-flex align-items-center gap-3">
                  <Button className="btn-book btns" onClick={handleShow}>
                    {isShowMore ? `${t("readless")}` : `${t("readmore")}`}
                  </Button>

                  <Link
                    to="/easylife/contact"
                    target=""
                  >
                    <Button className="btn-learn btns bg-transparent btn btn-primary">
                    {t("contactBtn")}
                    </Button>
                  </Link>
                  {/* <Link
                    to="https://play.google.com/store/apps/details?id=com.sslwireless.easylife"
                    target=" _blank"
                  >
                    <Button className="btn-learn btns bg-transparent btn btn-primary">
                      Get the App
                    </Button>
                  </Link> */}
                </div>
              </>
            </div>
            <div className="right-img">
              <img src={bannerItem?.img} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutEsayLifeBanner;
