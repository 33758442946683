import React from "react";
import BgBannerImg from "../../assets/BG/bgLeft.png";
import BannerImg from "../../assets/easylife/banner/banner-5.jpg";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

const bannerItems = [
  {
    id: 1,
    title: `Digital Offers`,
    desc: `To create digital ID by signing up to the EasyLife app and avail attractive discounts on entertainment and lifestyle brands including hospitals.`,
    img: BannerImg,
  },
];

const EasyLifeDigitalOffersBanner = () => {
  return (
    <div style={BgStyle}>
      <div className="body-container secondary-container banner-container">
        {bannerItems.map((bannerItem) => (
          <div key={bannerItem.id} className="banner-content">
            <div className="left-content">
              <h1 className="title banner-title">{bannerItem?.title}</h1>
              <p className="text banner-text">{bannerItem?.desc}</p>
            </div>
            <div className="right-img">
              <img src={bannerItem?.img} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EasyLifeDigitalOffersBanner;
