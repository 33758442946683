import React from "react";
import Banner from "../Banner/Banner";
import OurService from "../OurService/OurService";
import MobileApp from "../MobileApp/MobileApp";
import ClinetReview from "../ClientReview/ClinetReview";
import PartnerClient from "../PartnerClient/PartnerClient";
import Blog from "../Blog/Blog";
import Contact from "../Contact/Contact";
import HomeBannerCardOne from "../HomeBannerCardOne/HomeBannerCardOne";
import HomeBannerCardTwo from "../HomeBannerCardTwo/HomeBannerCardTwo";
import CorporatePartnerSection from "../../CorporateSolutions/CorporatePartnerSection";
import useTitle from "../../../hooks/useTitle";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../../../components/Loading/Loading";
import ChatBoot from "../../../components/ChatBoot/ChatBoot";
import PopUp from "../../PopUp/PopUp";
const Home = () => {
  // useTitle("Guardian Life Insurance Ltd.");
  const [metaTags, setMetaTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [popUpData, setPopUpData] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowPopup(true);
      sessionStorage.setItem("popup", 1);
    });
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_host}/api/v1/pop-up`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setPopUpData(data?.data?.popUpList[0]);
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    getPaginationList();
  }, [metaTags]);

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/seo?pages=Home`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setMetaTags(data?.data?.seo);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      {popUpData?.popUpStatus === true && (
        <div>
          <PopUp isLoading={isLoading} popUpData={popUpData} show={showPopup} onHide={closePopup} />
        </div>
      )}
      {metaTags?.map((data) => (
        <Helmet>
          <title>{data?.title} - Guardian Life Insurance Ltd. </title>
          <meta name="description" content={data?.description} />
          <meta
            name="keywords"
            content={data?.keywords?.map((keys) => keys?.keywordsName)}
          />
        </Helmet>
      ))}
      {/* <ChatBoot /> */}
      <Banner />
      <OurService />
      <HomeBannerCardTwo />
      {/* <OurPlans /> */}
      <MobileApp />
      <ClinetReview />
      <HomeBannerCardOne />
      <CorporatePartnerSection />
      {/* <PartnerClient /> */}
      <Blog />
      <Contact />
    </div>
  );
};

export default Home;
